<template>
  <div id="app">
    <top-company-info></top-company-info>
    <topNav @openDialog="openDialog" ></topNav>
    <div id="swipercom">
      <div class="swiper-container" id="swiperIndex">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in imgs" :key="i" @click="imgClick(item.url)">
            <img :src="item.pic" alt="" />
          </div>
        </div>

        <div class="swiper-pagination"></div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <swiper-bottom></swiper-bottom>
    <part-two></part-two>
    <part-three></part-three>
    <part-four @openDialog="openDialog"></part-four>
    <part-five @openDialog="openDialog"></part-five>
    <part-six></part-six>
    <part-seven></part-seven>
    <part-eight @openDialog="openDialog"></part-eight>
    <part-nine></part-nine>
    <part-ten></part-ten>
    <service></service>
    <el-dialog
      :visible.sync="dialogVisible"
      center
      :show-close="true"
      width="65%">
      <div class="dialog-content">
        <div class="dialog-content-left">
           <img src="./assets/dialog-img1.png" alt="">
           <div style="display: flex;flex-direction: column;align-items: center;margin-top: 8px;">
              <div style="width: 353px;height: 45px;background: linear-gradient(90deg, rgba(6,216,251,0) 0%, #06D8FB 43%, #06D8FB 51%, rgba(6,216,251,0) 100%);text-align: center;line-height: 45px;color: #fff;font-size:18px">
                   7年专注智慧商业
              </div>
              <div style="font-size: 38px;font-weight:bold;color:#ffffff;margin-top:20px">
                全面助力企业数字化转型成功
              </div>
              <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px;">
                  <div style="width: 64px;height: 4px;background: linear-gradient(90deg, rgba(6,216,251,0) 0%, #06D8FB 100%);margin-top: 4px;margin-right: 4px;"></div>
                  <div style="font-size: 16px;color:#ffffff;letter-spacing: 2px;">全方位系统功能·全链路运营服务</div>
                  <div style="width: 64px;height: 4px;background: linear-gradient(90deg, #06D8FB 0%, rgba(6,216,251,0) 100%);margin-top: 4px;margin-right: 4px;"></div>
              </div>
              <img src="./assets/dialog-img2.png" alt="" style="margin-top:30px">
           </div>
        </div>
        <div class="dialog-content-right">
            <div style="display: flex;align-items: center;width: 100%;">
                <img src="./assets/dialog-img3.png" alt="">
                <div style="margin-left:15px">
                  <div style="color:#333333;font-size:24px;font-weight: bold;">我是您的专属私域顾问</div>
                  <div style="color: rgba(51,51,51,0.7);font-size:18px;letter-spacing: 4px;margin-top:4px">扫码加微信 领取专属红包</div>
                </div>
            </div>
            <div style="font-size: 24px;font-weight: bold;color: #2E5EFA;margin-top:34px">
              扫码免费领取《十大标杆电商品牌案例介绍》
            </div>            
            <img src="./assets/dialog-img-code.png" alt="" style="margin-top:40px">
            <div class="main-conte-right-button">
                <div style="letter-spacing: 4px;">扫码添加</div>
                <div style="    width: 1px;background-color: #ffffff;height: 20px;"></div>
                <div style="letter-spacing: 4px;">免费领取</div>
            </div>
            <div style="margin-top:20px;display:flex;">
              <svg t="1692867019418" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3988" width="20" height="20"><path d="M109.696 267.264q0-53.248 29.696-106.496 31.744-58.368 60.416-69.632 14.336-6.144 38.912-12.288t39.936-6.144q8.192 0 12.288 2.048 10.24 3.072 30.72 44.032 6.144 10.24 16.384 30.72t20.48 35.84 17.408 30.72q2.048 2.048 10.24 14.336t12.288 20.48 4.096 16.384q0 11.264-16.384 27.648t-35.84 31.744-34.816 30.72-16.384 25.6q0 5.12 3.072 13.312t4.096 11.264 8.192 14.336 7.168 10.24q43.008 78.848 99.328 135.168t134.144 99.328q1.024 0 10.24 6.144t14.336 8.192 11.264 5.12 13.312 2.048q10.24 0 25.6-15.36t30.72-35.84 31.744-35.84 28.672-16.384q7.168 0 15.36 4.096t20.48 12.288 14.336 10.24q14.336 8.192 30.72 17.408t36.864 20.48 30.72 17.408q39.936 19.456 43.008 29.696 2.048 4.096 2.048 12.288 0 15.36-6.144 39.936t-12.288 38.912q-11.264 28.672-69.632 61.44-53.248 28.672-105.472 28.672-16.384 0-30.72-2.048t-32.768-7.168-26.624-8.192-31.744-11.264-28.672-10.24q-55.296-20.48-99.328-48.128-73.728-45.056-151.552-122.88t-122.88-151.552q-27.648-44.032-47.104-99.328-2.048-5.12-10.24-28.672t-12.288-31.744-8.192-26.624-7.168-32.768-2.048-29.696z" fill="#333333" p-id="3989"></path></svg>
              <div style="letter-spacing: 4px;margin-left:4px">热线：15216771448</div>          
            </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "swiper/css/swiper.css"; //引入swiper样式   
import Swiper from "swiper"; //引入swiper  

import topCompanyInfo from './components/top_company_info.vue'
import topNav from './components/top_nav.vue'
import swiperBottom from './components/swiper_bottom.vue'
import partTwo from './components/part_two.vue'
import partThree from './components/part_three.vue'
import partFour from './components/part_four.vue'
import partFive from './components/part_five.vue'
import partSix from './components/part_six.vue'
import partSeven from './components/part_seven.vue'
import partEight from './components/part_eight.vue'
import partNine from './components/part_nine.vue'
import partTen from './components/part_ten.vue'
import service from './components/service.vue'
export default {
  metaInfo: {
    title: "芸众智慧商城-供应链中台系统-服务商系统广州市芸众信息科技有限公司 ",
    titleTemplate: "芸众智慧商城-供应链中台系统-服务商系统广州市芸众信息科技有限公司",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      //轮播图   
      imgs: [
        { pic: require("./assets/swiper5.jpg"),url:'https://www.yunzmall.com/plugins/shop_server/articleContent/606?i=10' },
        { pic: require("./assets/swiper4.jpg"),url:'https://www.yunzmall.com/plugins/shop_server/articleContent/581?i=10' },
        { pic: require("./assets/swiper1.jpg"),url:'https://www.yunzmall.com/plugins/shop_server/articleContent/520?i=10' },
        { pic: require("./assets/swiper2.jpg"),url:'https://www.yunzmall.com/plugins/shop_server/articleContent/561?i=10' },
        { pic: require("./assets/swiper3.png") },
      ],
      dialogVisible:false
    };
  },

  methods: {
    openDialog(){
      this.dialogVisible = true
    },
    isPhone() {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      return true;
    } else {
      return false;
    }
    },
    imgClick(url){
      if(url){
         window.open(url)
      }
    }
  },
  components: {topCompanyInfo,topNav,swiperBottom,partTwo,partThree,partFour,partFive,partSix,partSeven,partEight,partNine,partTen,service},
  mounted() {
    document.dispatchEvent(new Event("render-event"));
    var mySwiper = new Swiper("#swiperIndex", {
      //配置分页器内容

      loop: true, // 循环模式选项
      pagination: {
        el: ".swiper-pagination", //换页器与哪个标签关联  
        clickable: true, //分页器是否可以点击 
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
    });

    mySwiper.el.onmouseover = function () {
      //鼠标放上暂停轮播  
      mySwiper.autoplay.stop();
    };
    mySwiper.el.onmouseleave = function () {
      mySwiper.autoplay.start();
    };
    const that = this
     setTimeout( () =>{
      that.dialogVisible = true;
     },15000)
  },
  created(){
    if(this.isPhone()){
      window.location.href = 'https://www.yunzmall.com/addons/yun_shop/?menu#/home?i=10'
    }
  }
};
</script>

<style scoped>
body {
  padding: 0;
  margin: 0;
}
#swipercom{
  width: 100%;
}
#swipercom img{
  width: 100%;
}
::v-deep .swiper-pagination-bullet{
  width: 46px !important;
  border-radius: 0 !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 50px;
}
::v-deep .el-dialog__header{
  padding: 0;
}
::v-deep .el-dialog__footer{
  padding: 0;
}
::v-deep .el-dialog--center .el-dialog__body{
  padding: 0;
}
::v-deep  .el-dialog--center{
border-radius: 10px;
}
.dialog-content{
  display: flex;
}
.dialog-content-left{
  width: 50%;
  /* height: 610px; */
  background: linear-gradient(138deg, #203DF5 0%, #3E85FF 100%);
  border-radius: 10px 0px 0px 10px;
  padding: 30px;
}
.dialog-content-right{
  width: 45%;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-conte-right-button{
  width: 186px;
  height: 37px;
  background: #2B57F9;
  border-radius: 23px;
  margin-top:30px;
  padding:9px 20px;
  color:#ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
}
</style>
