<template>
  <div class="part-six">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       专业稳定技术 为企业报价护航
      </div>
      <div
        style="font-size: 30px; color: rgba(51, 51, 51, 0.7); margin-top: 20px"
      >
       私有化部署，插件分离架构，保障企业数据安全
      </div>
    </div> 
    <div style=" display: flex;justify-content: center;align-items: center;">
      <div style="margin-right:30px;margin-top:80px" @mouseenter="isHoverPrev = true" @mouseleave="isHoverPrev = false"> 
        <img src="../assets//button-left-active.png"  class="prev-button" alt="" v-if="isHoverPrev">
        <img src="../assets/button-left.png" alt="" class="prev-button" v-else>
        </div>
    <div id="swipercom" >
      <div class="swiper-container" id="swiperIndex2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in imgList" :key="i">
            <img :src="item.pic" alt="" />
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>  
    <div style="margin-left:30px;margin-top:80px" @mouseenter="isHoverNext = true" @mouseleave="isHoverNext = false" >
      <img src="../assets/button-right-active.png" class="next-button" alt="" v-if="isHoverNext">
      <img src="../assets/button-right.png" alt="" class="next-button" v-else> 
    </div>
    </div>  
  </div>
</template>

<script>
import "swiper/css/swiper.css"; //引入swiper样式  
import Swiper from "swiper"; //引入swiper  
export default {
  data () {
    return {
      imgList:[
        { pic: require("../assets/part-six-img1.png") },
        { pic: require("../assets/part-six-img2.png") },
        { pic: require("../assets/part-six-img3.png") },
      ],
      isHoverPrev:false,
      isHoverNext:false
    };
  },

  methods: {

  },

  components: {},
  mounted() {
    document.dispatchEvent(new Event("render-event"));
    var mySwiper = new Swiper("#swiperIndex2", {
      //配置分页器内容

      loop: true, // 循环模式选项
      pagination: {
        el: ".swiper-pagination", //换页器与哪个标签关联
        clickable: true, //分页器是否可以点击 
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".next-button",
        prevEl: ".prev-button",
      },
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
    });

    mySwiper.el.onmouseover = function () {
      //鼠标放上暂停轮播
      mySwiper.autoplay.stop();
    };
    mySwiper.el.onmouseleave = function () {
      mySwiper.autoplay.start();
    };
  },  
};

</script>
<style  scoped>
 .part-six{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  padding-bottom: 112px;
 }
 .title{
  margin-top: 100px;
 }
 #swipercom{
  margin-top: 74px;
 }
 #swiperIndex2{
  width: 1200px;
 }
 #swiperIndex2 img{
  width: 100%;
 }
 .swiper-button-prev:after, .swiper-button-next:after{
  display: none;
 }
 .next-button{
  width: 46px !important;
  height: 46px;
  cursor: pointer;
 }
 .prev-button{
  width: 46px !important;
  height: 46px;
  cursor: pointer;
 }
</style>