import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta';


import 'element-ui/lib/theme-chalk/index.css';
import { Tabs,TabPane,Dialog} from "element-ui";

Vue.use(Tabs);
Vue.use(TabPane)
Vue.use(Dialog)
Vue.config.productionTip = false

Vue.use(Meta)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
