<template>
  <div class="part-five">
    <div class="title">
      <div style="font-size: 40px; color: #ffffff; font-weight: bold">
       面向多产业业务生态的供应链全栈解决方案
      </div>
      <div
        style="font-size: 30px; color: #ffffff; margin-top: 20px"
      >
       加速互联网与千行百业的融合，挖掘创新解决方案
      </div>
    </div>    
     <div class="part-five-content">
       <div class="content-left">
         <div :class="{'content-left-item' :true,'active':activeIndex == 0}" @click="changeActive(0)">工厂规模化供应链解决方案</div>
         <div :class="{'content-left-item' :true,'active':activeIndex == 1}" @click="changeActive(1)">课程类内容供应链解决方案</div>
         <div :class="{'content-left-item' :true,'active':activeIndex == 2}" @click="changeActive(2)">聚合CPS供应链解决方案</div> 
         <div :class="{'content-left-item' :true,'active':activeIndex == 3}" @click="changeActive(3)">企业级福利供应链解决方案</div>
         <div :class="{'content-left-item' :true,'active':activeIndex == 4}" @click="changeActive(4)">政企供销社供应链解决方案</div> 
       </div>
       <div class="content-right">
         <div class="content-right-img">
           <img :src="currentIndex.img" alt="">
         </div>
          <div style="margin-top:24px;font-size:18px;color:#ffffff">{{currentIndex.des}}</div>
          <div class="content-right-button" @click="openDialog">免费领取</div>
          <div class="content-right-list">
              <span style="font-size:16px;color:#ffffff">相关案例</span>
              <img :src="item" alt="" v-for="(item,index) in currentIndex.logoList" :key="index">
          </div>
       </div>
    </div>  
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex:0,
      rightShowList:[
        {
          img:require('../assets/part-five-img1.png'),
          des:'整合自有货源线上管理，通过API、集采、一件代发等方式，实现一站式的平台化和数字化线上采购，降低渠道管理成本，提升采购，出货效率。',
          logoList:[require('../assets/part-five-logo1.png'),require('../assets/part-five-logo2.png'),require('../assets/part-five-logo3.png'),require('../assets/part-five-logo4.png'),require('../assets/part-five-logo5.png')]
        },
        {
          img:require('../assets/part-five-img2.png'),
          des:'将“高效的引流资源—课程+视频+图文+素材”，通过是数字化中台方式，实现一键矩阵式分发给终端销售平台，完成内容运营能力的无限复制。',
          logoList:[require('../assets/part-five-logo15.png')]
        },
        {
          img:require('../assets/part-five-img3.png'),
          des:'整合自有货源线上管理，通过API、集采、一件代发等方式，实现一站式的平台化和数字化线上采购，降低渠道管理成本，提升采购，出货效率。',
          logoList:[require('../assets/part-five-logo10.png'),require('../assets/part-five-logo11.png'),require('../assets/part-five-logo12.png'),require('../assets/part-five-logo13.png'),require('../assets/part-five-logo14.png')]
        },   
        {
          img:require('../assets/part-five-img4.png'),
          des:'将传统的“人力单向决策，员工被动接受”的福利管理模式转变为“员工自主选择”的自由福利模式，实现员工福利发放与员工满意度之间的契合。',
          logoList:[require('../assets/part-five-logo1.png'),require('../assets/part-five-logo2.png'),require('../assets/part-five-logo3.png'),require('../assets/part-five-logo4.png'),require('../assets/part-five-logo5.png')]
        },
        {
          img:require('../assets/part-five-img5.png'),
          des:'搭建供销生态圈，加强收购资源整合管理能力，打通供销链路效率化，拓展销售渠道全网链路，助力终端销售营销运营能力，实现链路一体化，提高收益能效。',
          logoList:[require('../assets/part-five-logo15.png')]
        },                     
      ],
      currentIndex:{}
    };
  },

  methods: {
    changeActive(index){
      this.activeIndex = index
      this.currentIndex = this.rightShowList[index]
    },
    openDialog(){
      this.$emit('openDialog')
    }
  },
  created(){
    this.currentIndex = this.rightShowList[this.activeIndex]
  },
  components: {},
};

</script>
<style  scoped>
.part-five{
  background-image: url('../assets/part-five-bg.png');
  /* height: 820px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}
.title{
  margin-top: 100px;
 }
.part-five-content{
 margin-top: 50px;
 background: rgba(0,0,0,0.3);
 display: flex;
}
.content-left{  
background: rgba(0,0,0,0.3);
padding: 60px 0 0 0 ;
}
.content-left-item{
  padding: 25px 18px;
  font-size: 20px;
  color: #ffffff;
}
.active{
  background-color: #3E85FF;
}
.content-right{
  padding: 26px 26px 48px 33px;
  max-width: 840px;
  position: relative;
}
.content-right-button{
  padding: 10px 24px;
  color: #ffffff;
  font-size: 14px;
  background-color: #2A7EFB;
  width: 56px;
  border-radius: 4px;
  margin-top: 5px;
  position: absolute;
  right: 23px;
  cursor: pointer;  
}
.content-right-button:hover{
  opacity: 0.7;
}
.content-right-list{
  margin-top: 100px;
  margin-left: 20px;
      display: flex;
    align-items: center;
    /* justify-content: space-around; */
}
.content-right-list img{
  margin-left: 20px;
}
.content-right-img{
  display: flex;
  justify-content: center;
  height: 270px;
}
</style>