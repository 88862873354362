<template>
  <div class="part-four">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       300+营销玩法 从“人-场-货”升级运营
      </div>
      <div
        style="font-size: 30px; color: rgba(51, 51, 51, 0.7); margin-top: 20px"
      >
       搭建会员成长体系，增加产品营销方式，升级线上卖货场景
      </div>
    </div>      
    <div class="logo-list">
       <div class="logo-item" v-for="(item,index) in list" :key="index" @click="changeActive(index)">
           <img :src="item.img" alt="">
           <div :class="{'logo-item-title':true,'actives': index == activeIndex}">{{item.title}}</div>
           <div v-if="index == activeIndex" style="width: 133px;height: 8px;background: #203DF5;border-radius: 25px;position: relative;top: 22px;left: 17px;"></div>
       </div>
    </div>
     <div class="line" >
     </div>
     <div class="bottom">
       <div>
         <div class="bottom-title">{{activeBottom.title}}</div>
         <div class="bottom-des">{{activeBottom.des1}}</div>
         <div class="bottom-des2">{{activeBottom.des2}}</div>
         <!-- <div class="bottom-button" @click="openDialog">点击领取</div> -->
       </div>
       <img :src="activeBottom.img" alt="" style="margin-left:44px">
     </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list:[
        {
          img:require('../assets/part-four-logo1.png'),
          title:'帮您全景曝光'
        },
        {
          img:require('../assets/part-four-logo2.png'),
          title:'帮您留住客户'
        },
        {
          img:require('../assets/part-four-logo1.png'),
          title:'帮您转化成交'
        }        
      ],
      activeIndex:0,
      activeBottom:{},
      bottomList:[
        {
          img:require('../assets/part-four-img1.png'),
          title:'多渠道展示商品 打开销路',
          des1:'链接14亿微信用户，在微信生态中曝光',
          des2:"商品，让商品直接触达用户，收割14亿微信流量红利"
        },
        {
          img:require('../assets/part-four-img2.png'),
          title:'多行业多场景 多点爆破',
          des1:'200+种营销功能自由组合使用，搭配不',
          des2:"同的场景及营销计划，玩法更新颖，效果更明显"
        },
        {
          img:require('../assets/part-four-img3.png'),
          title:'全链路私域营销 策略加持',
          des1:'用权益把流量变成留量，把用户变成忠实',
          des2:'粉丝，撬动资源持续创造收益，真正做到降本增效'
        },                
      ]
    };
  },

  methods: {
    changeActive(index){
      this.activeIndex = index
      this.activeBottom = this.bottomList[index]
    },
    openDialog(){
      this.$emit('openDialog')
    }
  },

  components: {},
  created(){
    this.activeBottom = this.bottomList[this.activeIndex]
  }
};

</script>
<style  scoped>
.part-four{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.title{
  margin-top: 100px;
 }
 .logo-list{
    display: flex;
    margin-top: 100px;
 }
 .logo-item{
  text-align: center;
  cursor: pointer;
 }
 .logo-item:nth-child(2){
  margin: 0 250px;
 }
 .logo-item-title{
  margin-top: 20px;
  font-size: 28px;
  font-weight: bold;
 }
 .actives{
  color: #203DF5;
 }
 .line{
  margin-top: 18px;
width: 1200px;
height: 2px;
background: #203DF5;
border-radius: 0px 0px 0px 0px;
opacity: 0.2;
 }
 .bottom{
  margin-top: 20px;
  display: flex;
  height: 500px;
 }
 .bottom img{
  object-fit: contain;
 }
 .bottom-title{
  margin-top: 114px;
  color: #000000; 
  font-size: 40px;
  font-weight: bold;
 }
 .bottom-des{
  margin-top: 30px;
  color: #000000;
  font-size: 24px;
 }
 .bottom-des2{
    color: #000000;
  font-size: 24px;
 }
 .bottom-button{
  background-color: #203DF5;
  border-radius: 30px;
  font-size: 28px;
  font-weight: bold;
  padding: 10px 44px;
  color: #ffffff;
  width: 112px;
  margin-top: 53px;
  cursor: pointer;
 }
 .bottom-button:hover{
  opacity: 0.6;
 }
</style>