<template>
  <div class="part-eight">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       40000+企业站点部署 实现业绩增长
      </div>
      <div
        style="font-size: 20px; color: #203DF5; margin-top: 20px;cursor: pointer;"
        @click="openDialog"
      >
       查看更多案例>
      </div>
    </div> 
    <div class="taps">
      <div class="taps-item" :style="{color:activeIndex == index ? '#203DF5' : '#000000'}" @click="changIndex(index)" v-for="(item,index) in tapsList" :key="index">{{item}}
        <div :class="{'blod-line':activeIndex == index }"></div>
      </div>

    </div>
    <div class="taps-line"></div>
    <div class="img-show">
      <div class="img-show-left">
        <div style="opacity: 0.3;">
          <svg t="1692777021543" class="icon" viewBox="0 0 1316 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="92115" width="40" height="40"><path d="M175.542857 789.942857c-40.228571-40.228571-62.171429-102.4-62.171428-182.857143 0-98.742857 32.914286-190.171429 98.742857-274.285714s157.257143-138.971429 274.285714-168.228571l32.914286-7.314286 87.771428 164.571428-51.2 21.942858c-43.885714 18.285714-84.114286 40.228571-117.028571 65.828571-32.914286 25.6-54.857143 47.542857-69.485714 73.142857 62.171429 0 113.371429 18.285714 153.6 54.857143s62.171429 80.457143 62.171428 131.657143c0 54.857143-21.942857 102.4-62.171428 135.314286-43.885714 40.228571-106.057143 62.171429-179.2 62.171428-62.171429 0-117.028571-25.6-168.228572-76.8z m603.428572 0c-40.228571-40.228571-62.171429-102.4-62.171429-182.857143 0-98.742857 32.914286-190.171429 98.742857-274.285714s157.257143-138.971429 274.285714-168.228571l32.914286-7.314286 87.771429 164.571428-51.2 21.942858c-43.885714 18.285714-84.114286 40.228571-117.028572 65.828571-32.914286 25.6-54.857143 47.542857-69.485714 73.142857 62.171429 0 113.371429 18.285714 153.6 54.857143s62.171429 80.457143 62.171429 131.657143c0 54.857143-21.942857 102.4-62.171429 135.314286-43.885714 40.228571-106.057143 62.171429-179.2 62.171428-58.514286 0-117.028571-25.6-168.228571-76.8z" fill="#DCDCDC" p-id="92116"></path></svg>
        </div>
        <div >
        <div style="font-size:28px;color:#FFFFFF;margin-top:30px">{{activeItem.title}}</div>
        <div style="    font-size: 20px;margin-top: 13px;color: #ffffff;">{{activeItem.des}}</div>
        </div>
        <div style="opacity: 0.3;position: relative;">
          <svg style="position: absolute;bottom: 0;" t="1692777111625" class="icon" viewBox="0 0 1316 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="92266" width="40" height="40"><path d="M1141.028571 234.057143c40.228571 40.228571 62.171429 102.4 62.171429 182.857143 0 98.742857-32.914286 190.171429-98.742857 274.285714s-157.257143 138.971429-274.285714 168.228571l-32.914286 7.314286-87.771429-164.571428 51.2-21.942858c43.885714-18.285714 84.114286-40.228571 117.028572-65.828571 32.914286-25.6 54.857143-47.542857 69.485714-73.142857-62.171429 0-113.371429-18.285714-153.6-54.857143S731.428571 405.942857 731.428571 354.742857c0-54.857143 21.942857-102.4 62.171429-135.314286 43.885714-40.228571 106.057143-62.171429 179.2-62.171428 62.171429 0 117.028571 25.6 168.228571 76.8z m-603.428571 0c40.228571 40.228571 62.171429 102.4 62.171429 182.857143 0 98.742857-32.914286 190.171429-98.742858 274.285714s-157.257143 138.971429-274.285714 168.228571l-32.914286 7.314286-87.771428-164.571428 51.2-21.942858c43.885714-18.285714 84.114286-40.228571 117.028571-65.828571 32.914286-25.6 54.857143-47.542857 69.485715-73.142857-62.171429 0-113.371429-18.285714-153.6-54.857143s-62.171429-80.457143-62.171429-131.657143c0-54.857143 21.942857-102.4 62.171429-135.314286 43.885714-40.228571 106.057143-62.171429 179.2-62.171428 58.514286 0 117.028571 25.6 168.228571 76.8z" fill="#DCDCDC" p-id="92267"></path></svg>
        </div>
      </div>
      <div class="img-show-right">
         <img :src="activeItem.img" alt="" style="width:100%;height:100%">
      </div>
    </div>
    <div style="margin-top:40px">
      <img src="../assets/part-eight-logoList.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeItem:{},
      activeName:'second',
      dataList:[
        {
          title:"保力茶公社",
          des:'便捷化的购物模式，随时看茶购茶，分享品茶经验，交流茶道艺术。',
          img:require('../assets/part-eight-img1.png')
        },
        {
          title:"尚美葆美业",
          des:'重视皮肤健康，还原美丽人生。',
          img:require('../assets/part-eight-img2.png')
        },
        {
          title:"昊祥邻里聚金广场",
          des:'全新升级的精品社区生活中心，全自持物业统一运营管理。',
          img:require('../assets/part-eight-img3.png')
        },
        {
          title:"欣果铺子",
          des:'全新升级的精品社区生活中心，全自持物业统一运营管理。',
          img:require('../assets/part-eight-img4.png')
        },
        {
          title:"蒸善堂",
          des:'中医健康理疗门店连锁品牌。',
          img:require('../assets/part-eight-img5.png')
        }                                
      ],
      tapsList:['茶水酒品','美妆个护','日常生活','食品饮料','健康保健'],
      activeIndex:0
    };
  },

  methods: {
    changIndex(index){
     this.activeIndex = index
     this.activeItem = this.dataList[this.activeIndex]
    },
    openDialog(){
      this.$emit('openDialog')
    }
  },

  components: {},
  mounted(){
    this.activeItem = this.dataList[this.activeIndex]
  }
};

</script>
<style  scoped>
.part-eight{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  padding-bottom: 112px;
}
.title{
  margin-top: 56px;
}
.taps{
  margin-top: 50px;
  display: flex;
  color: #000000;
  font-size: 20px;
}
.taps-item{
  width: 240px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.taps-line{
  margin-top: 14px;
  width: 1200px;
  height: 2px;
  background: #203DF5;
  opacity: 0.2;
 }
.blod-line{
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: #203DF5;
    bottom: -16px;
}
.img-show{
  display: flex;
  margin-top: 60px;
  width: 100%;
}
.img-show-left{
/* width: 418px; */
flex: 4;
background: linear-gradient(44deg, #203DF5 0%, #3E85FF 100%);
padding: 87px 112px 79px 17%;
display: flex;
}
.img-show-right{
  flex: 7;
}
</style>