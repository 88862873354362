<template>
  <div :class="{'fix-nav': navBarFixed}">
     <div id="nav" class="top-nav" >
      <div>
        <img src="../assets/nav-logo1.png" alt="" />
        <img
          src="../assets/nav-logo2.png"
          alt=""
          style="margin-left: 10px; position: relative; bottom: 10px"
        />
      </div>
      <div class="font-list">
        <span class="font">首页</span>
        <span class="font"  @mouseenter="show"  @click="notShow"
          >产品服务
          <svg
            t="1692252291568"
            :class="{
              icon: true,
              'is-transform': isactive,
              'not-transform': !isactive,
            }"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="90457"
            width="12"
            height="12"
          >
            <path
              d="M8.533333 290.133333c0-10.666667 4.266667-21.333333 12.8-29.866666 17.066667-17.066667 42.666667-17.066667 59.733334 0L503.466667 682.666667l422.4-422.4c17.066667-17.066667 42.666667-17.066667 59.733333 0 8.533333 8.533333 12.8 19.2 12.8 29.866666 0 10.666667-4.266667 21.333333-12.8 29.866667L531.2 772.266667c-17.066667 17.066667-42.666667 17.066667-59.733333 0L21.333333 320c-8.533333-8.533333-12.8-19.2-12.8-29.866667z"
              p-id="90458"
            ></path>
          </svg>
        </span>
        <span class="font" @click="openDialog">方案报价</span>
        <span class="font" @click="toLink('https://www.yunzmall.com/plugins/shop_server/home?i=10')">应用市场</span>
        <span class="font" @click="openDialog">渠道合作</span>
        <span class="font" @click="toLink('https://www.yunzmall.com/plugins/shop_server/articleContent/425?i=10')">关于芸众</span>
      </div>
      <div class="nav-right" @click="openDialog">
        <img
          src="../assets/nav-logo3.png"
          alt=""
          srcset=""
          style="margin-right: 5px"
        />
        <span>演示</span>
      </div>
      <div
        @click="openDialog"
        style="
          margin-left: 20px;
          font-weight: bold;
          color: #203df5;
          line-height: 40px;
          cursor: pointer;
        "
      >
        免费版下载
      </div>
    </div>
    <div class="pull-content"  v-if="isactive"  @mouseleave="notShow">
      <div class="pull-content-top">
        <strong style="color: #203df5; font-size: 18px"
          >企业级智慧电商系统</strong
        >
        <div
          style="
            display: inline-block;
            margin-left: 20px;
            color: #203df5;
            font-size: 14px;
            padding: 10px;
            background-color: #eff5ff;
            border-radius: 16px;
          "
        >
          线上做生意，找芸众科技
        </div>
        <div class="system-list">
          <div class="system-list-item" v-for="(item,index) in systemList1" :key="index">
           <img :src="item.img" alt="">      
            <strong class="system-list-title" >{{item.title}}</strong>       
            <div class="list-info">
                <div class="list-info-item" v-for="(items,index) in item.list" :key="index"><div class="point"></div>{{items}}</div>
            </div>              
          </div>                                      
        </div>
      </div>
      <div class="pull-content-bottom">
        <strong style="color: #203df5; font-size: 18px"
          >企业项目落地运营服务</strong
        >
        <div
          style="
            display: inline-block;
            margin-left: 20px;
            color: #203df5;
            font-size: 14px;
            padding: 10px;
            background-color: #ffffff;
            border-radius: 16px;
          "
        >
          高效合规
        </div>
        <div class="system-list">
          <div class="system-list-item" v-for="(item,index) in systemList2" :key="index">
           <img :src="item.img" alt="">      
            <strong class="system-list-title" >{{item.title}}</strong>       
            <div class="list-info">
                <div class="list-info-item" v-for="(items,index) in item.list" :key="index"><div class="point"></div>{{items}}</div>
            </div>              
          </div>                                      
        </div>
      </div>
      <div style="background: linear-gradient(282deg, rgba(6,216,251,0.07) 0%, #203DF5 100%);">
      <div class="pull-content-logo">
        承接个性化定制开发 · 解决企业数字化转型全方位技术需要
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isactive: false,
            systemList1:[
        {
          img: require("../assets/list1.png"),
          title:'通用商城解决方案',
          list:['品牌B2C商城','B2B2C商城','分销商城']
        },
        {
          img: require("../assets/list2.png"),
          title:'新零售商城解决方案',
          list:['团队极差商城系统','链动新商店','微商云库存']
        },
        {
          img: require("../assets/list3.png"),
          title:'单门店商城解决方案',
          list:['在线项目预约系统','POS收银买单系统','共享股东系统']
        }, 
        {
          img: require("../assets/list4.png"),
          title:'O2O多商户解决方案',
          list:['异业联盟多商户','连锁店商城','共享股东系统']
        },      
        {
          img: require("../assets/list5.png"),
          title:'企业管理系统',
          list:['项目管理系统','电子合同系统','律师平台系统']
        },
        {
          img: require("../assets/list6.png"),
          title:'知识付费商城解决方案',
          list:['在线视频点播系统','线下活动报名','付费文档商城系统']
        },
        {
          img: require("../assets/list7.png"),
          title:'供应链商城系统',
          list:['胜天半子供应链商城','聚合CPS商城系统','供应链中台商城系统']
        },
        {
          img: require("../assets/list8.png"),
          title:'供应链中台',
          list:['产品供应链中台系统解决方案','内容运营中台解决方案','CPS中台解决方案']
        },                                                                  
      ],
      systemList2:[
        {
          img: require("../assets/list9.png"),
          title:'运营策划',
          list:['品牌全案服务','创始人/品牌IP打造服务','产品策划服务','活动策划服务']
        },
        {
          img: require("../assets/list10.png"),
          title:'运维服务',
          list:['单台服务器托管运维','集群部署运维托管服务','服务器迁移服务']
        },   
        {
          img: require("../assets/list11.png"),
          title:'商城装修拍摄',
          list:['商品拍摄服务','店铺首页基础设计服务','商品主图设计服务']
        },    
        {
          img: require("../assets/list12.png"),
          title:'云连锁策划运营',
          list:['抖音云连锁策划服务','私域云连锁全托管运营']
        },                          
      ],
      navBarFixed: false,
    };
  },

  methods: {
    show() {
      this.isactive = true;
    },   
    notShow(){
      const that = this
      setTimeout(() =>{
         that.isactive = false;
      },100)

    },
    openDialog(){
      this.$emit('openDialog')
    } ,
    watchScroll(){
      // 滚动的距离   
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
     // 容器的高度  
            var offsetTop = document.querySelector("#nav").offsetHeight;   
            if (scrollTop > offsetTop) {
              this.navBarFixed = true;
            } else {
              this.navBarFixed = false;
            }          
    },
    toLink(url){
      window.open(url)
    }
  },
  mounted() {
  // 事件监听滚动条  
    window.addEventListener("scroll", this.watchScroll);
  },
  destroyed() {
  // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },  
  components: {},
};

</script>
<style scoped>
.top-nav {
  height: 67px;
  /* margin: 10px 250px; */
  display: flex;
  justify-content: center;
  align-items: center;  
}
.font-list {
  margin-left: 40px;
  line-height: 40px;
}
.font {
  font-size: 14px;
  font-weight: bold;
  margin-left: 40px;
  cursor: pointer;
}
.font:hover {
  padding-bottom: 5px;
  border-bottom: 1px solid #203df5;
}
.is-transform {
  transform: rotate(180deg);
  transition: transform 0.2s;
}
.not-transform {
  transform: rotate(360deg);
  transition: transform 0.2s;
}
.nav-right {
  width: 76px;
  height: 32px;
  border-radius: 17px;
  background-color: rgb(32, 61, 245);
  margin-left: 80px;
  margin-top: 5px;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-right:hover {
  opacity: 0.7;
  cursor: pointer;
}
.pull-content {
  margin-left: 20%;
  width: 1200px;
  position: absolute;
  z-index: 3;
}
.pull-content-top {
  padding: 22px 40px;
   background-color: #ffffff;
}
.pull-content-bottom{
    padding: 22px 40px;
   background-color: #EFF5FF;
}
.pull-content-logo{
  background-image: url("../assets/background1.png");
  height: 100px;
    line-height: 100px;
    padding-left: 40px;
    font-size: 24px;
    color: #ffffff;
}
.system-list{
  padding-left: 20px;  
  display: flex;
  flex-wrap: wrap;
}
.system-list-title{
  margin-left: 10px;
  font-size: 16px;
}
.list-info{
  margin-top: 10px;
  margin-left: 10px;
}
.list-info-item{
  margin-bottom: 10px;
  color: #000000;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 500;
}
.point{
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #3E85FF;
    opacity: 0.2;
    border-radius: 100%;
    margin-right: 13px;
}
.system-list-item{
  margin-right: 100px;
  width: calc((100% - 340px) / 4);
  margin-top: 20px;
}
.system-list-item:nth-child(4n){
  margin-right: 0;
}
.fix-nav{
    position: fixed;
    z-index: 3;
    width: 100%;
    background-color: #ffffff;
    top: 0;
}
</style>