<template>
  <div class="part-nine">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       芸众最新动态 
      </div>
    </div>
    <div class="trends-list">
      <div class="trends-list-item" v-for="(item,index) in trendsList" :key="index">
         <div class="trends-list-item-top" :style="{backgroundImage: `url(${item.bgImg})`}">
          <div class="top-content">
            <div style="color:#333333;font-weight: 300;font-size:20px">{{item.esTitle}}</div>
            <div class="trend-line"></div>
            <div style="color:#333333;font-size:28px;font-weight: bold;margin-top:8px">{{item.title}}</div>
          </div>
         </div>
         <div class="trends-list-item-content">
            <div style="color:#333333;font-size:18px;cursor: pointer;height: 48px;" @click="toUrl(item.middleUrl)">{{item.middleTitle}}</div>
            <div style="margin-top:12px;color: rgba(51,51,51,0.7);font-size:14px">{{item.middleDes}}</div>
            <div style="width: 336px;height: 1px;background-color:rgba(51,51,51,0.3);margin-top:15px;margin-bottom:20px"></div>
            <div class="version-list-item" v-for="(items,indexs) in item.versionList" :key="indexs">
                <div style="color: rgba(51,51,51,0.7);font-size:14px;display: inline-block;">{{items.data}}</div>
                <div :title="items.versionTitle" class="new-content" style="" @click="toUrl(items.url)">{{items.versionTitle}}</div>
            </div>
         </div>
      </div>
    </div>     
  </div>
</template>

<script>
export default {
  data () {
    return {
      //芸众最新动态列表 
      trendsList:[
        {
          bgImg:require('../assets/part-nine-bg1.png'),
          esTitle:'Functional updates',
          title:'功能更新',
          middleTitle:'优化 | 供应链中台支付方式增加拉卡拉收银台',
          middleDes:'对接拉卡拉聚合收银台，帮助中台集成多种支付方式，如微信、支付宝、云闪付、手机POS支付等，满足客户多样化的支付需求。',
          middleUrl:'https://www.yunzmall.com/plugins/shop_server/articleContent/618?i=10',
          versionList:[
            {
              data:'01-23',
              versionTitle:'功能 | 【消息通知】正式上线',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/613?i=10'
            },
            {
              data:'01-23',
              versionTitle:'优化 | 供应链中台页面链接设置及推广中心优化',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/612?i=10'
            },
            {
              data:'01-16',
              versionTitle:'功能 | 供应链中台接入13条优质供应链渠道',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/606?i=10'
            },
            {
              data:'01-09',
              versionTitle:'功能｜【积分价格显示】正式上线',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/600?i=10'
            }                                    
          ]
        },
        {
          bgImg:require('../assets/part-nine-bg2.png'),
          esTitle:'Company News',
          title:'公司动态',
          middleTitle:'公告 | 服务商系统更名，链接更多数据能力',
          middleDes:'服务商系统正式更名为“数据通”系统。随着企业对于数据应用需求的日益增长，我们决定为企业提供更为全面的数据采集、处理能力。',
          middleUrl:'https://www.yunzmall.com/plugins/shop_server/articleContent/598?i=10',
          versionList:[
            {
              data:'11-30',
              versionTitle:'芸众 | 技术产品售后服务体系',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/570?i=10'
            },
            {
              data:'11-30',
              versionTitle:'芸众 | 资质及荣誉证明',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/569?i=10'
            },
            {
              data:'09-25',
              versionTitle:'直播课程 | 芸众X百世：ERP如何解决电商领域问题',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/541?i=10'
            },
            {
              data:'08-21',
              versionTitle:'芸众 | 领航未来：Jenkins与DevOps的颠覆性融合',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/521?i=10'
            },
            {
              data:'07-24',
              versionTitle:'直播课程 | 是什么内容让企业老板们涌进直播间？',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/500?i=10'
            }                                    
          ]
        } ,
        {
          bgImg:require('../assets/part-nine-bg3.png'),
          esTitle:'Industry News',
          title:'行业新闻',
          middleTitle:'资讯 | 1.22-1.26电商圈发生了哪些大事',
          middleDes:'每周一分钟，打破信息差！来看看电商圈又发生了哪些你不能错过的大事件。',
          middleUrl:'https://www.yunzmall.com/plugins/shop_server/articleContent/615?i=10',
          versionList:[
            {
              data:'01-19',
              versionTitle:'资讯 | 1.15-1.19电商圈发生了哪些大事', 
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/608?i=10'
            },             
            {
              data:'01-12',
              versionTitle:'资讯 | 1.08-1.12电商圈发生了哪些大事',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/604?i=10'
            },
            {
              data:'01-06',
              versionTitle:'政策 | 国家数据局等部门关于印发《“数据要素×”三年行动计划（2024—2026年）》的通知',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/597?i=10'
            },
            {
              data:'01-05',
              versionTitle:'资讯 | 1.01-1.05电商圈发生了哪些大事',
              url:'https://www.yunzmall.com/plugins/shop_server/articleContent/595?i=10'
            },                                   
          ]
        }                
      ]
    };
  },

  methods: {
    toUrl(url){
      window.open(url)
    }
  },

  components: {},
};

</script>
<style  scoped>
  .part-nine{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  padding-bottom: 112px;
  background-image: url('../assets/part-nine-bg.png');    
  }
  .title{
  margin-top: 56px;
}
.trends-list{
  margin-top: 95px;
  display: flex;
}
.trends-list-item{
  background-color: #FFFFFF;
  width: 388px;
  border-radius: 4px;
}
.trends-list-item:nth-child(2){
  margin:0 18px
}
.trends-list-item:hover{  
box-shadow: 0px 13px 20px 1px rgba(117,139,185,0.2);
}
.trends-list-item-top{
 height: 179px;
 width: 388px;
}
.top-content{
  padding: 45px 0 0 40px;
}
.trend-line{
  width: 40px;
  height: 6px;
  background: #2A7EFB;
}

.trends-list-item-content{
  padding: 20px 26px 26px 26px;
}
.version-list-item{
  margin-top: 15px;
}
.new-content{
margin-left:11px;
font-size:14px;
color:#333333;
cursor: pointer;
display: inline-block;
width: 274px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
line-height: 13px;
}
</style>