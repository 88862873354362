<template>
  <div class="part-three">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       30+电商业务模式 满足市面上80%电商玩法
      </div>
      <div
        style="font-size: 30px; color: rgba(51, 51, 51, 0.7); margin-top: 20px"
      >
        芸众7年开发经验，深入研究企业线上营销模式
      </div>
    </div>   
    <div class="list">
      <div class="list-item" v-for="(item,index) in list"  :key="index">
          <img :src="item.img" alt="">
          <div class="list-item-title">{{item.title}}</div>
          <div class="list-item-des">{{item.des}}</div>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  data () {
    return {
      list:[
        {
          img:require('../assets/part-three-logo1.png'),
          title:'B2C商城',
          des:'搭建独立平台，提高销售利润'
        },
        {
          img:require('../assets/part-three-logo2.png'),
          title:'B2B2C商城',
          des:'提高市场覆盖，独立后台管理'
        },
        {
          img:require('../assets/part-three-logo3.png'),
          title:'S2B2C商城',
          des:'降低招商成本，提高平台背书'
        },
        {
          img:require('../assets/part-three-logo4.png'),
          title:'单门店线上商城',
          des:'提高老客户复购，覆盖更多潜在客户'
        },
        {
          img:require('../assets/part-three-logo5.png'),
          title:'O2O多商家商城',
          des:'扩大服务类目，线上线下无缝衔接'
        },
        {
          img:require('../assets/part-three-logo6.png'),
          title:'连锁门店商城',
          des:'统一平台经营，提高门店数字化经营'
        },
        {
          img:require('../assets/part-three-logo7.png'),
          title:'CPS联盟商城',
          des:'整合多平台推广资源'
        },
        {
          img:require('../assets/part-three-logo8.png'),
          title:'知识付费商城',
          des:'集中提供各类知识付费产品和服务'
        },
        {
          img:require('../assets/part-three-logo9.png'),
          title:'线上咨询商城',
          des:'随时随地互联网咨询服务'
        },
        {
          img:require('../assets/part-three-logo10.png'),
          title:'社区自提商城',
          des:'线上拼团下单，就近自提点提货'
        },
        {
          img:require('../assets/part-three-logo11.png'),
          title:'加权分润',
          des:'分润商城营业额，多劳多得'
        },
        {
          img:require('../assets/part-three-logo12.png'),
          title:'业绩奖励',
          des:'设置关系链团队业绩指标'
        },
        {
          img:require('../assets/part-three-logo13.png'),
          title:'周度考核',
          des:'周期考核达标奖励'
        },
        {
          img:require('../assets/part-three-logo14.png'),
          title:'推N返N',
          des:'奖励会员第N层分润'
        }, 
        {
          img:require('../assets/part-three-logo15.png'),
          title:'链动新店商',
          des:'矩阵化销售渠道'
        },                                                                                                                
      ]
    };
  },

  methods: {},

  components: {},
};

</script>
<style  scoped>
 .part-three{
  margin-top: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F2F5FC;
  padding-bottom: 100px;
  padding-left: 24rem;
  padding-right: 24rem;
 }
 .title{
  margin-top: 100px;
 }
 .list{
  margin-top: 80px;
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(5,1fr);  
 }
 .list-item{
  padding: 1.25rem 0;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  margin-right: 2rem;
  margin-bottom: 2.17rem;
 }
 .list-item:nth-child(5n){
  margin-right: 0px;
 }
 .list-item:hover{
 box-shadow: 0px 13px 20px 1px rgba(117,139,185,0.2);
 }
 .list-item-title{
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
 }
 .list-item-des{
   color: rgba(0,0,0,0.7);
   font-size: 12px;
   margin-top: 5px;
 }
</style>