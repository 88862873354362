<template>
  <div class="swiper-bottom">
    <div class="content">
      <div style="display: flex;">
        <img :src="img1" alt="" srcset="" />
        <div
          style="
            margin-left: 30px;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div style="color:#000000;font-size:20px">视频号分享员 | 分享购物锁定关系</div>
          <div style="font-size:16px;color:rgba(0,0,0,0.5);">小程序商城打通视频号小店，增加分享路径直接锁定关系</div>
        </div>
      </div>
      <div class="line"></div>
      <div style="display: flex;width:510px">
        <img :src="img2" alt="" srcset="" />
        <div
          style="
            margin-left: 30px;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div style="color:#000000;font-size:20px">超级拼团玩法  | 自动开团极速裂变</div>
          <div style="font-size:16px;color:rgba(0,0,0,0.5);">赠送开团权益，免费开团邀请，循环自动开团</div>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img1: require("../assets/swiper-bottom1.png"),
      img2: require("../assets/swiper-bottom2.png"),
    };
  },

  methods: {},

  components: {},
};
</script>
<style >
.swiper-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  position: relative;
  bottom: 40px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px; 
  box-shadow: 0px 0px 31px 1px rgba(212,223,246,0.3);
}
.line{
      height: 60px;
    width: 1px;
    margin: 0 50px;
    background-color: rgba(51,51,51,0.5);
}
</style>