<template>
  <div class="part-seven">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
       标准交付服务体系 以客户为中心
      </div>
    </div> 
    <div class="logo-list">
       <div class="logo-list-item" v-for="(item,index) in logoList" :key="index">
          <img :src="item.logo" alt="">
          <div class="logo-list-item-title">
            {{item.title}}
          </div>
          <div class="logo-list-item-des">
            {{item.des}}
          </div>
       </div> 
    </div>     
  </div>
</template>

<script>
export default {
  data () {
    return {
      logoList:[
        {
          logo:require('../assets/part-seven-logo1.png'),
          title:'专业商务顾问',
          des:'专业的产品顾问，提供一对一运营模式分析与设计'
        },
        {
          logo:require('../assets/part-seven-logo2.png'),
          title:'一对一售后对接',
          des:'7x24H一对一客服全程在线服务'
        },
        {
          logo:require('../assets/part-seven-logo3.png'),
          title:'免费更新升级',
          des:'一次购买即享受终生免费升级系统的权限'
        },
        {
          logo:require('../assets/part-seven-logo4.png'),
          title:'齐全操作文档',
          des:'帮助企业快速上手进阶经营能力'
        }                         
      ]
    };
  },

  methods: {},

  components: {},
};

</script>
<style  scoped>
.part-seven{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  padding-bottom: 112px;
  background-color: #F2F5FC;
 }
 .title{
  margin-top: 100px;
 }
 .logo-list{
  display: flex;
  margin-top: 100px;
  text-align: center;
 }
 .logo-list-item-title{
  margin-top: 10px;
    font-size: 30px;
    color: #000000;
    /* margin-left: 0px; */
    /* margin-right: 20px; */
    font-weight: 500;
 }
 .logo-list-item-des{
    margin-top: 15px;
    width: 260px;
    font-size: 20px;
    color: rgba(0,0,0,0.7);
    /* margin-right: 20px; */
 }
 .logo-list-item:nth-child(2){
  margin: 0 50px;
 }
 .logo-list-item:nth-child(3){
  margin-right: 50px;
 }
</style>