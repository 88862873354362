<template>
    <div class="top-company-info">
      40000+企业部署商用 | 100+产品软著 | 广东省高新技术企业 | 重合同信用企业 |
      科技型中小企业 | 专精特新中小企业 <strong>服务热线:15216771448</strong>
    </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  methods: {},

  components: {},
};

</script>
<style >
.top-company-info {
  height: 40px;
  background-color: #eff5ff;
  text-align: center;
  line-height: 40px;
  padding-left: 340px;
}
</style>