<template>
  <div class="part-two">
    <div class="title">
      <div style="font-size: 40px; color: #000000; font-weight: bold">
        芸众赋能企业数字转型技术产品
      </div>
      <div
        style="font-size: 30px; color: rgba(51, 51, 51, 0.7); margin-top: 20px"
      >
        紧随电商发展趋势 助力企业数字化升级
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-img"><img :src="currentPicture" alt="" /></div>
      <div class="tap">
        <div class="tap-item" @click="changeActive(1)">
          <div style="position: relative;left: 5px;" v-if="isActive == 1"><svg t="1692344373178" style="transform: rotate(89deg);" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="47573" width="20" height="20"><path d="M940.91599999 355.04l-346.22199999 490.345c-37.099 51.667-129.847 51.667-166.946 0L80.48699999 355.04c-37.099-51.667-10.497-137.063 63.70100001-137.063L878.25600001 217.977C952.45400001 217.977 978.016 303.373 940.91599999 355.04z" p-id="47574" fill="#203DF5"></path></svg></div>
          <div
            style="padding:32px 22px 32px 28px;box-shadow: 0px 3px 10px 1px rgba(17,70,172,0.1);border-radius: 10px 10px 10px 10px;background: #FFFFFF;display: flex;width:226px"
            :class="{'is-actives': isActive == 1}"
          >
            <img src="../assets/part-two-logo1.png" alt="" v-if="isActive != 1" />
            <img src="../assets/part-two-logo1-active.png" alt="" v-else>
            <span style="font-size: 20px;color: #333333;margin-left: 10px;" :class="{'color-is-active': isActive == 1}">智慧商城系统</span>
          </div>
        </div>
        <div class="tap-item" @click="changeActive(2)">
          <div style="position: relative;left: 5px;" v-if="isActive == 2"><svg t="1692344373178" style="transform: rotate(89deg);" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="47573" width="20" height="20"><path d="M940.91599999 355.04l-346.22199999 490.345c-37.099 51.667-129.847 51.667-166.946 0L80.48699999 355.04c-37.099-51.667-10.497-137.063 63.70100001-137.063L878.25600001 217.977C952.45400001 217.977 978.016 303.373 940.91599999 355.04z" p-id="47574" fill="#203DF5"></path></svg></div>
          <div
            style="padding:32px 22px 32px 28px;box-shadow: 0px 3px 10px 1px rgba(17,70,172,0.1);border-radius: 10px 10px 10px 10px;background: #FFFFFF;display: flex;width:226px"
            :class="{'is-actives': isActive == 2}"
          >
            <img src="../assets/part-two-logo2.png" alt="" v-if="isActive != 2" />
            <img src="../assets/part-two-logo2-active.png" alt="" v-else>
            <span style="font-size: 20px;color: #333333;margin-left: 10px;" :class="{'color-is-active': isActive == 2}">供应链中台系统</span>
          </div>
        </div>
        <div class="tap-item" @click="changeActive(3)">
          <div style="position: relative;left: 5px;" v-if="isActive == 3"><svg t="1692344373178" style="transform: rotate(89deg);" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="47573" width="20" height="20"><path d="M940.91599999 355.04l-346.22199999 490.345c-37.099 51.667-129.847 51.667-166.946 0L80.48699999 355.04c-37.099-51.667-10.497-137.063 63.70100001-137.063L878.25600001 217.977C952.45400001 217.977 978.016 303.373 940.91599999 355.04z" p-id="47574" fill="#203DF5"></path></svg></div>
          <div
            style="padding:32px 22px 32px 28px;box-shadow: 0px 3px 10px 1px rgba(17,70,172,0.1);border-radius: 10px 10px 10px 10px;background: #FFFFFF;display: flex;width:226px"
            :class="{'is-actives': isActive == 3}"
          >
            <img src="../assets/part-two-logo3.png" alt="" v-if="isActive != 3" />
            <img src="../assets/part-two-logo3-active.png" alt="" v-else>
            <span style="font-size: 20px;color: #333333;margin-left: 10px;" :class="{'color-is-active': isActive == 3}">服务商系统</span>
          </div>
        </div>   
        <div class="tap-item" @click="changeActive(4)">
          <div style="position: relative;left: 5px;" v-if="isActive == 4"><svg t="1692344373178" style="transform: rotate(89deg);" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="47573" width="20" height="20"><path d="M940.91599999 355.04l-346.22199999 490.345c-37.099 51.667-129.847 51.667-166.946 0L80.48699999 355.04c-37.099-51.667-10.497-137.063 63.70100001-137.063L878.25600001 217.977C952.45400001 217.977 978.016 303.373 940.91599999 355.04z" p-id="47574" fill="#203DF5"></path></svg></div>
          <div
            style="padding:32px 22px 32px 28px;box-shadow: 0px 3px 10px 1px rgba(17,70,172,0.1);border-radius: 10px 10px 10px 10px;background: #FFFFFF;display: flex;width:226px"
            :class="{'is-actives': isActive == 4}"
          >
            <img src="../assets/part-two-logo4.png" alt="" v-if="isActive != 4" />
            <img src="../assets/part-two-logo4-active.png" alt="" v-else>
            <span style="font-size: 20px;color: #333333;margin-left: 10px;" :class="{'color-is-active': isActive == 4}">定制开发服务</span>
          </div>
        </div>                             
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture1: require("../assets/part-two1.jpg"),
      picture2: require("../assets/part-two2.jpg"),
      picture3: require("../assets/part-two3.jpg"),
      picture4: require("../assets/part-two4.jpg"),
      currentPicture: require("../assets/part-two1.jpg"),
      isActive:1
    };
  },

  methods: {
    changeActive(index){
      this.isActive = index
      if(index == 1){
        this.currentPicture = this.picture1
      }else if(index == 2){
        this.currentPicture = this.picture2
      }else if(index == 3){
        this.currentPicture = this.picture3
      }else{
        this.currentPicture = this.picture4
      }
    }
  },

  components: {},
};
</script>
<style scope>
.part-two {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  text-align: center;
}
.bottom {
  margin-top: 50px;
  display: flex;
}
.bottom-img {
  /* background-color: #f8fafd; */
  border-radius: 10px;
}
.bottom-img img{
  height: 425px;
}
.tap {
  margin-left: 10px;
}
.tap-item{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
  cursor: pointer;
}
.is-actives{
  background: linear-gradient(to right,#203DF5,#3E85FF) !important;
}
.color-is-active{
  color: #ffffff !important;
}
</style>